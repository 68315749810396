import tokens from "@nimbus-ds/tokens/dist/js/tokens";
import { mapValues } from "lodash";
import { type Config } from "tailwindcss";
import plugin from "tailwindcss/plugin";

export const NIMBUS_ALERT_ID = "nimbus-alert";
export const NIMBUS_TOAST_ID = "nimbus-toast";
export const NIMBUS_TAG_ID = "nimbus-tag";
export const NIMBUS_MENU_BUTTON_ID = "nimbus-menu-button";
export const NIMBUS_RADIO_ID = "nimbus-radio";
export const NIMBUS_CHECKBOX_ID = "nimbus-checkbox";
export const NIMBUS_BADGE_ID = "nimbus-badge";
export const NIMBUS_SELECT_ID = "nimbus-select";

const NIMBUS_ALERT_SELECTOR_SVG = `div[data-override-id="${NIMBUS_ALERT_ID}"] svg`;

const NIMBUS_TOAST_CONTAINER_SELECTOR = `div[class^='nimbus-toast_container']`;

const NIMBUS_TOAST_SELECTOR_DIV = `div[data-override-id="${NIMBUS_TOAST_ID}"]`;
const NIMBUS_TOAST_SELECTOR_P = `div[data-override-id="${NIMBUS_TOAST_ID}"] p`;

const NIMBUS_TAG_SELECTOR = `div[data-override-id="${NIMBUS_TAG_ID}"]`;

const NIMBUS_MENU_BUTTON_SELECTOR = `button[data-override-id="${NIMBUS_MENU_BUTTON_ID}"]`;
const NIMBUS_MENU_BUTTON_SELECTOR_P = `button[data-override-id="${NIMBUS_MENU_BUTTON_ID}"] p`;

const NIMBUS_CHECKBOX_SELECTOR_SPAN = `input[data-override-id="${NIMBUS_CHECKBOX_ID}"] + span`;
const NIMBUS_CHECKBOX_SELECTOR_P = `input[data-override-id="${NIMBUS_CHECKBOX_ID}"] + span + p`;

const NIMBUS_RADIO_SELECTOR_DIV = `input[data-override-id="${NIMBUS_RADIO_ID}"] + div`;
const NIMBUS_RADIO_SELECTOR_P = `input[data-override-id="${NIMBUS_RADIO_ID}"] + div p`;

const NIMBUS_BADGE_SELECTOR_DIV = `div[data-override-id="${NIMBUS_BADGE_ID}"]`;

const NIMBUS_SELECT_SELECTOR_SELECT = `select[data-override-id="${NIMBUS_SELECT_ID}"]`;

export const PLAN_FEATURE_CHECK_ICON_COLOR = "#0B8656";
export const PLAN_FEATURE_CROSS_ICON_COLOR = "#62595D";

export default {
  content: ["./src/**/*.{js,ts,jsx,tsx,mdx}"],
  theme: {
    extend: {
      boxShadow: {
        ...mapValues(tokens.shadow.level, "value"),
        focus: "0px 0px 0px 3px #96C1FC", // primary-90
      },
      colors: {
        primary: mapValues(tokens.color.light.primary, "value"),
        success: mapValues(tokens.color.light.success, "value"),
        warning: mapValues(tokens.color.light.warning, "value"),
        danger: mapValues(tokens.color.light.danger, "value"),
        neutral: mapValues(tokens.color.light.neutral, "value"),
        "secondary-pure": "#2C3357",
      },
      screens: {
        sm: "640px",
      },
      backgroundImage: {
        "gradient-radial": "radial-gradient(var(--tw-gradient-stops))",
        "gradient-conic":
          "conic-gradient(from 180deg at 50% 50%, var(--tw-gradient-stops))",
      },
      animation: {
        "fade-in-delayed": "fade-in 1s ease-in-out 3s forwards",
      },
      keyframes: {
        "fade-in": {
          "0%": { opacity: "0" },
          "100%": { opacity: "1" },
        },
      },
    },
  },
  plugins: [
    plugin(function ({ addBase, theme }) {
      addBase({
        // Overrides Nimbus Alert styles
        [NIMBUS_ALERT_SELECTOR_SVG]: {
          width: theme("spacing.5"),
          height: theme("spacing.5"),
        },

        // Prevents Nimbus Toast container from taking up the entire available width
        [NIMBUS_TOAST_CONTAINER_SELECTOR]: {
          width: "fit-content",
          margin: "auto",
          zIndex: "99",
        },

        // Overrides Nimbus Toast styles
        [NIMBUS_TOAST_SELECTOR_DIV]: {
          paddingLeft: theme("padding.6"),
          paddingRight: theme("padding.6"),
          paddingTop: "14px",
          paddingBottom: "14px",
          height: "inherit",
        },
        [NIMBUS_TOAST_SELECTOR_P]: {
          fontSize: theme("fontSize.base"),
          lineHeight: theme("lineHeight.5"),
        },

        // Overrides Nimbus Tag styles
        [NIMBUS_TAG_SELECTOR]: {
          height: "inherit",
          padding: "0px",
        },

        // Overrides Nimbus MenuButton styles
        [NIMBUS_MENU_BUTTON_SELECTOR]: {
          gap: theme("gap.2"),
        },
        [NIMBUS_MENU_BUTTON_SELECTOR_P]: {
          fontSize: theme("fontSize.lg"),
          lineHeight: "normal",
        },

        // Overrides Nimbus Checkbox styles
        [NIMBUS_CHECKBOX_SELECTOR_SPAN]: {
          height: "24px",
          width: "24px",
          marginRight: theme("margin.1"),
        },
        [NIMBUS_CHECKBOX_SELECTOR_P]: {
          fontSize: theme("fontSize.lg"),
          lineHeight: theme("lineHeight.6"),
        },

        // Overrides Nimbus Radio styles
        [NIMBUS_RADIO_SELECTOR_DIV]: {
          display: "flex",
          alignItems: "center",
          height: "36px",
          paddingLeft: theme("padding.4"),
          paddingRight: theme("padding.4"),
        },
        [NIMBUS_RADIO_SELECTOR_P]: {
          fontSize: theme("fontSize.base"),
        },

        // Overrides Nimbus Badge styles
        [NIMBUS_BADGE_SELECTOR_DIV]: {
          padding: "10px 8px",
        },

        // Overrides Nimbus Select styles
        [NIMBUS_SELECT_SELECTOR_SELECT]: {
          height: theme("height.10"),
          lineHeight: theme("lineHeight.5"),
          fontSize: theme("fontSize.base"),
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
        },
      });
    }),
  ],
} satisfies Config;
