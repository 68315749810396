"use client";

import React, { type ComponentProps } from "react";
import { InfoCircleIcon } from "@nimbus-ds/icons";
import clsx from "clsx";
import { type FieldError } from "react-hook-form";

import TextInput, { type TextInputVariant } from "~/components/TextInput";

type Props = {
  label?: string;
  error?: FieldError | string;
  warning?: FieldError | string;
  containerClassName?: string;
} & ComponentProps<typeof TextInput>;

const TextField = React.forwardRef<HTMLInputElement, Props>(
  ({ label, error, warning, id, containerClassName, ...props }, ref) => {
    let variant: TextInputVariant = "primary";

    if (!!error || !!warning) {
      variant = error ? "danger" : "warning";
    }

    return (
      <div
        className={clsx("my-2 flex w-full flex-col gap-2", containerClassName)}
      >
        {!!label && (
          <label
            className="block text-base font-normal text-neutral-text-high sm:text-lg"
            htmlFor={id}
          >
            {label}
          </label>
        )}

        <TextInput id={id} type="text" variant={variant} {...props} ref={ref} />

        {!!error && (
          <span className="flex items-center gap-1 text-sm text-danger-text-low">
            <InfoCircleIcon size="small" />{" "}
            {typeof error === "string" ? error : error.message}
          </span>
        )}

        {!!warning && (
          <span className="flex items-center gap-1 text-sm text-warning-text-low">
            <InfoCircleIcon size="small" />{" "}
            {typeof warning === "string" ? warning : warning.message}
          </span>
        )}
      </div>
    );
  },
);

TextField.displayName = "TextField";

export default TextField;
