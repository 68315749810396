"use client";

import { type ReactNode } from "react";
import { Dialog } from "@headlessui/react";

import { Button } from "~/components";

export type ConfirmationModalProps = {
  title: string;
  description: string;
  children?: ReactNode;
  dismissButtonText: string;
  confirmButtonText: string;
  confirmButtonVariant?: "neutral" | "primary" | "danger";
  loading?: boolean;
  open: boolean;
  onDismiss: () => void;
  onConfirm: () => void;
};

const ConfirmationModal = ({
  title,
  description,
  children,
  dismissButtonText,
  confirmButtonText,
  confirmButtonVariant = "primary",
  loading = false,
  open,
  onDismiss,
  onConfirm,
}: ConfirmationModalProps) => {
  const handleDismissAction = () => !loading && onDismiss();

  const handleConfirmButtonClick = () => !loading && onConfirm();

  return (
    <Dialog open={open} onClose={handleDismissAction} className="z-50">
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
      <div className="fixed inset-0 mx-auto flex max-w-2xl items-center justify-center">
        <Dialog.Panel className="mx-4 min-w-fit rounded-lg bg-white p-6">
          <Dialog.Title className="text-xl font-medium leading-6">
            {title}
          </Dialog.Title>

          <Dialog.Description className="py-3 text-base text-neutral-text-disabled sm:py-4 sm:text-lg">
            {description}
          </Dialog.Description>

          {children}

          <div className="flex flex-col-reverse gap-2 sm:flex-row sm:justify-end sm:gap-4">
            <Button
              variant="neutral"
              onClick={handleDismissAction}
              disabled={loading}
            >
              {dismissButtonText}
            </Button>

            <Button
              variant={confirmButtonVariant}
              disabled={loading}
              onClick={handleConfirmButtonClick}
              loading={loading}
            >
              {confirmButtonText}
            </Button>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};

export default ConfirmationModal;
